<template>
  <v-tooltip bottom>
    <template #activator="{ on: onTooltip }">
      <v-menu
        v-model="show"
        :close-on-content-click="false"
        v-on="{ ...onTooltip }"
      >
        <template #activator="{ on }">
          <v-btn v-on="{ ...on, ...onTooltip }" icon :color="color">
            <v-icon>mdi mdi-tag-plus-outline</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <v-select
              :items="tagTypes"
              v-model="selectedTagType"
              item-value="pk"
              item-text="name"
              label="Typ šťítku"
              return-object
              :loading="loading"
            >
              <template #item="{ item }">
                <v-icon :color="item.color" class="mr-2">mdi mdi-tag</v-icon>
                {{ item.name }}
              </template>
            </v-select>
            <v-select
              v-if="selectedTagType"
              :items="availableTags"
              v-model="selectedTag"
              item-value="pk"
              item-text="name"
              return-object
              label="Štítek"
            >
              <template #prepend v-if="selectedTagType">
                <v-icon :color="selectedTagType.color">mdi mdi-tag</v-icon>
              </template>
            </v-select>
            <div class="d-flex">
              <v-btn
                color="primary"
                :disabled="selectedTag === null"
                @click="addTag()"
                :loading="saving"
                >Přidat
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="show = false">Zrušit</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
    {{ $t("label.addTag") }}
  </v-tooltip>
</template>

<script>
import axios from "axios";
import TaggedObjectMixin from "@/components/mixins/TaggedObjectMixin";

export default {
  name: "AddTagButton",

  mixins: [TaggedObjectMixin],

  props: {
    color: { required: false, type: String },
    objectType: { required: true, type: String }, // person or organization
    objectId: { required: true, type: Number },
  },

  data() {
    return {
      show: false,
      selectedTagType: null,
      selectedTag: null,
      loading: false,
      saving: false,
      tagTypes: [],
    };
  },

  computed: {
    availableTags() {
      // cleans out already assigned tags from the selection
      if (!this.selectedTagType) {
        return [];
      }
      return this.selectedTagType.tags
        .filter((item) => !this.tags.includes(item.pk))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  },

  methods: {
    async fetchTagTypes() {
      this.loading = true;
      try {
        let resp = await axios.get(`/api/tag-category/`);
        this.tagTypes = resp.data;
      } finally {
        this.loading = false;
      }
    },
    async addTag() {
      this.saving = true;
      await axios.post(`/api/tag/${this.selectedTag.pk}/${this.objectType}/`, {
        pk: this.objectId,
      });
      this.$emit("tag-added", this.selectedTag);
      this.saving = false;
      this.show = false;
    },
    async fetchTags() {
      // overrides the methods from mixin
      let tags = await this._fetchTags();
      this.tags = tags.map((item) => item.pk);
    },
  },

  watch: {
    show(value) {
      if (value) {
        this.fetchTagTypes();
        this.fetchTags();
      }
    },
    selectedTagType() {
      this.selectedTag = null;
    },
  },
};
</script>

<style scoped></style>
