import axios from "axios";

export default {
  props: {
    objectType: { required: true, type: String }, // person or organization
    objectId: { required: true, type: Number },
  },

  data() {
    return {
      tags: [],
    };
  },

  computed: {
    url() {
      if (this.objectType && this.objectId) {
        return `/api/${this.objectType}/${this.objectId}/tags/`;
      }
      return null;
    },
  },

  methods: {
    async fetchTags() {
      this.tags = await this._fetchTags();
      this.tags.sort((a, b) => {
        const catComp = a.category.name.localeCompare(b.category.name);
        if (catComp) {
          return catComp;
        }
        return a.name.localeCompare(b.name);
      });
    },
    async _fetchTags() {
      if (this.url) {
        let resp = await axios.get(this.url);
        return resp.data;
      }
      return [];
    },
  },

  watch: {
    url: {
      immediate: true,
      handler() {
        this.fetchTags();
      },
    },
  },
};
