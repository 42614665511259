<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <span class="note" v-on="on">
        <v-icon small color="#dddddd">{{
          internal ? "mdi-note-outline" : "mdi-note"
        }}</v-icon>
        {{ text }}
      </span>
    </template>
    {{ internal ? $t("label.internalNotes") : $t("label.publicNotes") }}
  </v-tooltip>
</template>

<script>
export default {
  name: "NoteText",

  props: {
    text: { required: true, type: String },
    internal: { default: false, type: Boolean },
  },
};
</script>

<style scoped lang="scss">
span.note {
  padding-left: 1rem;
  color: #999999;

  &::before {
    content: "";
  }
  &::after {
    content: "";
  }
}
</style>
