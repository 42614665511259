<template>
  <v-form v-model="valid">
    <v-container fluid>
      <v-row v-for="(contact, index) in contacts" :key="index">
        <v-col cols="12" md="4" class="py-0">
          <v-text-field
            v-model="contact.value"
            :label="
              contact.contact_type.name === '__default__'
                ? contact.contact_type.category.name
                : contact.contact_type.name
            "
            :prepend-inner-icon="
              contact.contact_type.icon
                ? contact.contact_type.icon
                : contact.contact_type.category.icon
                ? contact.contact_type.category.icon
                : null
            "
            counter
            :rules="
              contact.contact_type.alias === 'web'
                ? [urlRule]
                : contact.contact_type.used_field === 'email'
                ? [rules.email]
                : contact.contact_type.used_field === 'phone'
                ? [rules.phone]
                : []
            "
          >
            <template #append-outer>
              <v-icon @click="deleteContact(index)" color="red lighten-3"
                >mdi-delete</v-icon
              >
            </template>
            <template #counter>
              <span class="text-caption">
                <span class="subdued mr-1">{{ $t("label.preview") }}:</span>
                <ContactWidget :contact="contact" :emulate-icon="false" />
              </span>
            </template>
          </v-text-field>
        </v-col>
        <v-col class="pt-0" cols="12" sm="6" md="4">
          <v-text-field
            v-model="contact.public_notes"
            :label="$t('label.publicNotes')"
          />
        </v-col>
        <v-col
          class="pt-0"
          cols="12"
          sm="6"
          md="4"
          v-if="can('manage_internal_notes', 'contact')"
        >
          <v-text-field
            v-model="contact.internal_notes"
            :label="$t('label.internalNotes')"
          />
        </v-col>
        <v-col class="hidden-md-and-up" cols="12">
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto" class="align-self-center">
          <IconMenu :items="contactTypes" icon="mdi-plus" row />
        </v-col>
      </v-row>
      <v-row>
        <v-spacer />
        <v-col cols="auto">
          <slot name="buttons"></slot>
        </v-col>
        <v-col cols="auto">
          <v-btn
            @click="save()"
            color="primary"
            :disabled="!valid || saving"
            :loading="saving"
            >{{ $t("button.save") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import axios from "axios";
import IconMenu from "@/views/IconMenu";
import ContactWidget from "@/components/blocks/ContactWidget";
import { mapGetters } from "vuex";
import { validate } from "validate.js";
import FormValidationMixin from "@/components/mixins/FormValidationMixin";

export default {
  name: "EditContactsForm",
  mixins: [FormValidationMixin],
  components: { ContactWidget, IconMenu },
  props: {
    existingContacts: { required: true, type: Array },
    objectType: { required: true, type: String }, // person or organization
    objectId: { required: true, type: Number },
  },

  data() {
    return {
      contacts: [...this.existingContacts],
      contactTypes: [],
      toDelete: [],
      saving: false,
      valid: false,
    };
  },

  computed: {
    ...mapGetters(["can"]),
  },

  methods: {
    async save() {
      this.saving = true;
      try {
        let promises = [];
        for (const item1 of this.contacts.filter((item) => item.value.length)) {
          let rec = {
            internal_notes: item1.internal_notes,
            public_notes: item1.public_notes,
          };
          rec[item1.contact_type.used_field] = item1.value;
          if (item1.pk) {
            promises.push(axios.patch(`/api/contact/${item1.pk}/`, rec));
          } else {
            let subresp = await axios.post(`/api/contact/`, {
              ...rec,
              contact_type: item1.contact_type.pk,
            });
            let data = { contact: subresp.data.pk };
            data[this.objectType] = this.objectId;
            promises.push(
              axios
                .post(`/api/${this.objectType}-contact/`, data)
                .then((resp) => (item1.pk = resp.data.contact))
            );
          }
        }
        promises = [
          ...promises,
          ...this.toDelete.map((pk) => axios.delete(`/api/contact/${pk}/`)),
        ];
        await Promise.all(promises);
        this.toDelete = [];
        this.$emit("save");
      } catch (error) {
        console.error("Error saving data: ", error);
      } finally {
        this.saving = false;
      }
    },
    async fetchContactTypes() {
      let resp = await axios.get(`/api/contact-type/`);
      this.contactTypes = resp.data;
      this.contactTypes.forEach((item) => {
        item.title =
          item.name === "__default__" ? item.category.name : item.name;
        item.icon = item.icon || item.category.icon;
        item.action = this.addContact;
      });
    },
    addContact(contactType) {
      this.contacts.push({
        contact_type: contactType,
        value: "",
        icon: contactType.icon,
      });
    },
    deleteContact(index) {
      let items = this.contacts.splice(index, 1);
      if (items[0].pk) {
        this.toDelete.push(items[0].pk);
      }
    },
    addMissingContactFields() {
      let usedAliases = this.contacts.map(
        (item) => item.contact_type.category.alias
      );
      this.contactTypes
        .filter(
          (item) =>
            (item.category.alias === "email" ||
              item.category.alias === "phone") &&
            !usedAliases.includes(item.category.alias)
        )
        .forEach((item) =>
          this.contacts.push({ contact_type: item, value: "" })
        );
    },
    async reset() {
      this.contacts = [...this.existingContacts];
      this.toDelete = [];
      await this.fetchContactTypes();
      this.addMissingContactFields();
    },
    urlRule(value) {
      if (!/^https?:\/\//.test(value)) {
        return this.$t("validation.UrlMissingProtocol");
      }
      const result = validate({ website: value }, { website: { url: true } });
      if (result && result.website) {
        return this.$t("validation.UrlInvalid");
      }
      return true;
    },
  },

  watch: {
    existingContacts() {
      this.reset();
    },
  },

  async mounted() {
    await this.reset();
  },
};
</script>

<style scoped lang="scss">
.menu-container {
  background: white;
}
</style>
