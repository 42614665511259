import { preprocessObjectWithContacts } from "@/lib/contacts";

function personPhoneBookName(person) {
  let name = `${person.last_name}, ${person.first_name}`;
  if (person.middle_name) {
    name += ` ${person.middle_name}`;
  }
  return name;
}

function personFullName(person) {
  let name = "";
  if (person.first_name) name = person.first_name;
  if (person.middle_name) name += ` ${person.middle_name}`;
  if (person.last_name) name += ` ${person.last_name}`;
  return name;
}

function preprocessPerson(person) {
  preprocessObjectWithContacts(person);
  person.phonebook_name_ = personPhoneBookName(person);
  return person;
}

export { preprocessPerson, personPhoneBookName, personFullName };
