<template>
  <v-menu
    v-model="menu"
    nudge-bottom="32"
    v-if="can('delete', 'taggeditem') && !simple"
  >
    <template #activator="{ on }">
      <v-chip
        :color="tag.color || tag.category.color || '#999999'"
        class="mx-1 font-weight-light"
        :text-color="textColor"
        v-on="on"
      >
        {{ tag.name }}
        <v-icon x-small class="ml-1">mdi mdi-close</v-icon>
      </v-chip>
    </template>
    <v-sheet>
      <v-btn @click="remove()" elevation="0" color="warning"
        ><v-icon class="mr-1">mdi mdi-tag-off-outline</v-icon>
        {{ $t("button.remove") }}</v-btn
      >
    </v-sheet>
  </v-menu>
  <v-chip
    v-else
    :color="tag.color || tag.category.color || '#999999'"
    class="mx-1 font-weight-light"
    :text-color="textColor"
  >
    {{ tag.name }}
  </v-chip>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "TagWidget",

  props: {
    tag: { required: true, type: Object },
    objectType: { required: true, type: String }, // person, organization or perorglink
    objectId: { required: true, type: Number },
    simple: { default: false, type: Boolean }, // simple tag does not permit any action on it
  },

  data() {
    return {
      menu: false,
    };
  },

  computed: {
    ...mapGetters(["can"]),
    textColor() {
      return "white";
    },
  },

  methods: {
    async remove() {
      await axios.delete(
        `/api/tag/${this.tag.pk}/${this.objectType}/${this.objectId}/`
      );
      this.$emit("tag-del", this.tag);
    },
  },
};
</script>
