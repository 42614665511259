<template>
  <v-tooltip bottom>
    <template #activator="{ on: onTooltip }">
      <v-menu v-if="twoStep" nudge-bottom="32">
        <template #activator="{ on: onMenu }">
          <v-btn color="ghost" icon v-on="{ ...onTooltip, ...onMenu }">
            <v-icon>mdi-{{ icon }}</v-icon>
          </v-btn>
        </template>
        <v-sheet>
          <v-btn color="warning" @click="click">
            <v-icon class="mr-1">mdi-{{ icon }}</v-icon>
            {{ tooltipText }}
          </v-btn>
        </v-sheet>
      </v-menu>
      <v-btn v-else color="ghost" icon @click="click" v-on="onTooltip">
        <v-icon>mdi-{{ icon }}</v-icon>
      </v-btn>
    </template>
    {{ tooltipText }}
  </v-tooltip>
</template>

<script>
export default {
  name: "GhostButton",

  props: {
    type: {
      required: true,
      type: String,
      validator: (item) =>
        [
          "edit",
          "tag",
          "unlink",
          "add",
          "addPerson",
          "contacts",
          "suggestChange",
          "editLink",
        ].includes(item),
    },
    tooltip: { required: false, type: String },
    twoStep: { default: false, type: Boolean },
  },

  computed: {
    icon() {
      switch (this.type) {
        case "edit":
          return "pencil";
        case "tag":
          return "tag-outline";
        case "unlink":
          return "link-off";
        case "add":
          return "plus";
        case "addPerson":
          return "account-plus";
        case "contacts":
          return "pencil";
        case "suggestChange":
          return "tooltip-edit-outline";
        case "editLink":
          return "link";
        default:
          return "help-circle-outline";
      }
    },
    tooltipText() {
      if (this.tooltip) {
        return this.tooltip;
      }
      return this.$t(`tooltip.${this.type}`);
    },
  },

  methods: {
    click(event) {
      this.$emit("click", event);
    },
  },
};
</script>

<style scoped></style>
