<template>
  <v-autocomplete
    v-model="selected"
    :label="labelClean"
    :items="organizations"
    :loading="loading"
    item-text="name"
    item-value="pk"
    :return-object="returnObject"
    :disabled="disabled"
  >
    <template #item="{ item }">
      <span>{{ item.name }}</span>
      <v-spacer />
      <OrganizationIcon :org="item" />
    </template>
    <template #append-item>
      <v-list-item @click="createOrganization">
        <v-list-item-icon>
          <v-icon color="primary">mdi-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="font-weight-black primary--text">
          <v-list-item-title>{{
            $t("label.addOrganization")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <EditOrganizationDialog
        v-if="showCreateDialog"
        v-model="showCreateDialog"
        @save="addOrganization"
      />
    </template>
  </v-autocomplete>
</template>

<script>
import axios from "axios";
import OrganizationIcon from "@/components/blocks/OrganizationIcon";
import EditOrganizationDialog from "@/components/dialogs/EditOrganizationDialog";

export default {
  name: "OrganizationSelector",

  components: { EditOrganizationDialog, OrganizationIcon },

  props: {
    label: { default: "", type: String },
    value: { required: false }, // selected organization - either an object or pk - depends on returnObject
    returnObject: { default: false, type: Boolean },
    exclude: { default: () => [], type: Array }, // list of pk's of organizations to exclude from menu
    disabled: { default: false, type: Boolean },
  },

  data() {
    return {
      organizations: [],
      loading: false,
      selected: this.value,
      showCreateDialog: false,
    };
  },

  methods: {
    async fetchOrganizations() {
      try {
        this.loading = true;
        let resp = await axios.get("/api/organization/");
        this.organizations = resp.data
          .filter((item) => !this.exclude.includes(item.pk))
          .sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        console.error("Error fetching organizations: ", error);
      } finally {
        this.loading = false;
      }
    },
    createOrganization() {
      console.log("createOrganization");
      this.showCreateDialog = true;
    },
    addOrganization(org) {
      this.organizations.push(org);
      this.organizations.sort((a, b) => a.name.localeCompare(b.name));
      this.showCreateDialog = false;
      if (this.returnObject) {
        this.selected = org;
      } else {
        this.selected = org.pk;
      }
    },
  },

  computed: {
    labelClean() {
      return this.label ? this.label : this.$t("label.organization");
    },
  },

  mounted() {
    this.fetchOrganizations();
  },

  watch: {
    selected() {
      this.$emit("input", this.selected);
    },
    value() {
      this.selected = this.value;
    },
  },
};
</script>

<style scoped></style>
