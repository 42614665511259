<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-text-field
            readonly
            :value="contact.value"
            :label="$t('label.currentValue')"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="newValue"
            :label="$t('label.suggestedValue')"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="note"
            :label="$t('label.note')"
            filled
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-spacer />
        <v-col cols="auto">
          <slot name="buttons"></slot>
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary" @click="postSuggestion">{{
            $t("label.submitSuggestion")
          }}</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import axios from "axios";

export default {
  name: "SuggestContactChangeForm",

  props: {
    contact: { required: true, type: Object },
  },

  data() {
    return {
      newValue: "",
      note: "",
    };
  },

  methods: {
    async postSuggestion() {
      let data = {
        note: this.note,
        contact: this.contact.pk,
        kind: "mod",
        data: {},
      };
      data.data[this.contact.contact_type.used_field] = this.newValue;
      try {
        let resp = await axios.post("/api/contact-change-suggest/", data);
        this.$emit("save", resp.data);
      } catch (error) {
        console.error("Error sending suggestion:", error);
      }
    },
  },
};
</script>

<style scoped></style>
