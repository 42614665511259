<template>
  <span>
    <div class="icons float-right" v-if="showSuggestions && canSuggest">
      <GhostButton type="suggestChange" @click="showSuggestDialog = true" />
    </div>

    <v-tooltip bottom>
      <template #activator="{ on }">
        <span v-on="on">
          <v-icon v-if="icon" color="ghost" class="pr-1">{{ icon }}</v-icon>
          <span v-else-if="emulateIcon" class="ghost--text icon pr-1">{{
            firstLetter
          }}</span>

          <a :href="url" v-if="url" class="subdued" target="_blank">{{
            contact.value
          }}</a>
          <span v-else>{{ contact.value }}</span>
        </span>
      </template>
      {{ typeName }}
    </v-tooltip>

    <NoteText
      v-if="showNotes && contact.public_notes"
      :text="contact.public_notes"
    />
    <NoteText
      v-if="showNotes && contact.internal_notes"
      :text="contact.internal_notes"
      internal
    />
    <SuggestContactChangeDialog
      :contact="contact"
      v-if="showSuggestDialog"
      v-model="showSuggestDialog"
    />
  </span>
</template>

<script>
import { contactUrl } from "@/lib/contacts";
import NoteText from "@/components/blocks/NoteText";
import GhostButton from "@/components/icons/GhostButton";
import SuggestContactChangeDialog from "@/components/dialogs/SuggestContactChangeDialog";
import { mapGetters } from "vuex";
export default {
  name: "ContactWidget",

  components: { SuggestContactChangeDialog, GhostButton, NoteText },

  props: {
    contact: { required: true, type: Object },
    iconColor: { default: "#bbbbbb", type: String },
    showNotes: { default: false, type: Boolean },
    showSuggestions: { default: false, type: Boolean },
    emulateIcon: { default: true, type: Boolean }, // should an icon be emulated from name if not present?
  },

  data() {
    return {
      showSuggestDialog: false,
    };
  },

  computed: {
    ...mapGetters(["can"]),
    icon() {
      return (
        this.contact.contact_type.icon ||
        this.contact.contact_type.category.icon
      );
    },
    typeName() {
      return this.contact.contact_type.name === "__default__"
        ? this.contact.contact_type.category.name
        : this.contact.contact_type.name;
    },
    url() {
      if (this.contact) return contactUrl(this.contact);
      return "";
    },
    canSuggest() {
      return !this.can("change", "contact");
    },
    firstLetter() {
      return this.typeName.substr(0, 1);
    },
  },
};
</script>

<style scoped lang="scss">
span.icon {
  width: 1.5rem;
  text-align: center;
  display: inline-block;
  font-weight: 900;
  font-size: 125%;
}
</style>
