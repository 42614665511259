<template>
  <v-autocomplete
    v-model="selected"
    :label="labelClean"
    :items="roles"
    :loading="loading"
    item-text="text"
    item-value="pk"
    return-object
  >
    <template #item="{ item }">
      <v-icon class="subdued mr-1" color="#bbbbbb">mdi-account</v-icon>
      {{ item.person_ref }}
      &ndash;
      {{ item.org_ref }}
      <v-icon class="subdued ml-1" color="#bbbbbb">mdi-domain</v-icon>
    </template>
    <template #selection="{ item }">
      <v-icon class="subdued mr-1" color="#bbbbbb">mdi-account</v-icon>
      {{ item.person_ref }}
      &ndash;
      {{ item.org_ref }}
      <v-icon class="subdued ml-1" color="#bbbbbb">mdi-domain</v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
import axios from "axios";

export default {
  name: "PerOrgRoleSelector",

  props: {
    label: { default: "", type: String },
    value: { required: false, type: Object }, // selected role
  },

  data() {
    return {
      roles: [],
      loading: false,
      selected: this.value,
    };
  },

  methods: {
    async fetchRelationships() {
      try {
        this.loading = true;
        let resp = await axios.get("/api/per-org-relationship/");
        this.roles = resp.data;
        this.roles.forEach(
          (item) => (item.text = `${item.person_ref} - ${item.org_ref}`)
        );
        this.roles.sort((a, b) => a.text.localeCompare(b.text));
      } catch (error) {
        console.error("Error fetching roles: ", error);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    labelClean() {
      return this.label ? this.label : this.$t("label.role");
    },
  },

  mounted() {
    this.fetchRelationships();
  },

  watch: {
    selected() {
      this.$emit("input", this.selected);
    },
    value() {
      this.selected = this.value;
    },
  },
};
</script>

<style scoped></style>
