import { render, staticRenderFns } from "./EditContactsDialog.vue?vue&type=template&id=083a8eaf&scoped=true&"
import script from "./EditContactsDialog.vue?vue&type=script&lang=js&"
export * from "./EditContactsDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "083a8eaf",
  null
  
)

export default component.exports