<template>
  <div>
    <div
      v-for="(parents, psidx) in org.parent_organizations"
      :key="`p-${psidx}`"
    >
      <span v-for="(parent, pidx) in parents" :key="`p-${psidx}-${pidx}`">
        <organization-link :org="parent" /> /
      </span>
    </div>
  </div>
</template>
<script>
import OrganizationLink from "@/components/blocks/OrganizationLink";

export default {
  name: "OrganizationParentsBreadcrumb",
  components: { OrganizationLink },
  props: {
    org: { required: true, type: Object },
  },
};
</script>
