<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col>
          <OrganizationSelector
            v-model="orgPk"
            v-if="editedObject === 'organization'"
            :disabled="freezeEditedSide"
          />
          <PersonSelector
            v-model="perPk"
            v-else-if="editedObject === 'person'"
            :disabled="freezeEditedSide"
          />
          <v-alert v-else
            >Something is wrong - check the `editedSide` value</v-alert
          >
        </v-col>
        <v-col>
          <PerOrgRoleSelector v-model="role" />
        </v-col>
      </v-row>
      <v-row>
        <v-spacer />
        <v-col cols="auto">
          <slot name="buttons"></slot>
        </v-col>
        <v-col cols="auto" color="primary">
          <v-btn
            @click="save()"
            :disabled="invalid || saving"
            color="primary"
            :loading="saving"
            >{{ linkId ? $t("label.update") : $t("label.connect") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import OrganizationSelector from "@/components/blocks/OrganizationSelector";
import PerOrgRoleSelector from "@/components/blocks/PerOrgRoleSelector";
import axios from "axios";
import PersonSelector from "@/components/blocks/PersonSelector";

export default {
  name: "PerOrgLinkForm",

  components: { PerOrgRoleSelector, OrganizationSelector, PersonSelector },

  props: {
    linkId: { required: false, type: Number },
    perId: { required: false, type: Number },
    orgId: { required: false, type: Number },
    editedSide: {
      default: "",
      type: String,
      validator: (value) => ["", "person", "organization"].includes(value),
    },
    relation: { required: false, type: Object },
    freezeEditedSide: { default: false, type: Boolean }, // when given, only the relation can be edited
  },

  data() {
    return {
      orgPk: this.orgId,
      perPk: this.perId,
      role: this.relation,
      pk: this.linkId,
      saving: false,
      // guess which type is edited based on what is provides
      editedObject:
        this.editedSide !== ""
          ? this.editedSide
          : this.perId
          ? "organization"
          : "person",
    };
  },

  computed: {
    invalid() {
      return !(
        this.role && (this.editedObject === "person" ? this.perPk : this.orgPk)
      );
    },
  },

  methods: {
    async save() {
      try {
        const data = {
          person: this.perPk,
          organization: this.orgPk,
          rel_type: this.role.pk,
        };
        if (this.pk) {
          await axios.patch(`/api/perorglink/${this.pk}/`, data);
        } else {
          let resp = await axios.post(`/api/perorglink/`, data);
          this.pk = resp.data.pk;
        }
        this.$emit("save");
      } finally {
        this.saving = false;
      }
    },
  },

  watch: {
    relation() {
      this.role = this.relation;
    },
    orgId() {
      this.orgPk = this.orgId;
    },
    perId() {
      this.perPk = this.perId;
    },
    linkId() {
      this.pk = this.linkId;
    },
    editedSide() {
      this.editedObject =
        this.editedSide !== ""
          ? this.editedSide
          : this.perId
          ? "organization"
          : "person";
    },
  },
};
</script>

<style scoped></style>
