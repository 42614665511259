var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"fluid":""}},[_vm._l((_vm.contacts),function(contact,index){return _c('v-row',{key:index},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":contact.contact_type.name === '__default__'
              ? contact.contact_type.category.name
              : contact.contact_type.name,"prepend-inner-icon":contact.contact_type.icon
              ? contact.contact_type.icon
              : contact.contact_type.category.icon
              ? contact.contact_type.category.icon
              : null,"counter":"","rules":contact.contact_type.alias === 'web'
              ? [_vm.urlRule]
              : contact.contact_type.used_field === 'email'
              ? [_vm.rules.email]
              : contact.contact_type.used_field === 'phone'
              ? [_vm.rules.phone]
              : []},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-icon',{attrs:{"color":"red lighten-3"},on:{"click":function($event){return _vm.deleteContact(index)}}},[_vm._v("mdi-delete")])]},proxy:true},{key:"counter",fn:function(){return [_c('span',{staticClass:"text-caption"},[_c('span',{staticClass:"subdued mr-1"},[_vm._v(_vm._s(_vm.$t("label.preview"))+":")]),_c('ContactWidget',{attrs:{"contact":contact,"emulate-icon":false}})],1)]},proxy:true}],null,true),model:{value:(contact.value),callback:function ($$v) {_vm.$set(contact, "value", $$v)},expression:"contact.value"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('label.publicNotes')},model:{value:(contact.public_notes),callback:function ($$v) {_vm.$set(contact, "public_notes", $$v)},expression:"contact.public_notes"}})],1),(_vm.can('manage_internal_notes', 'contact'))?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('label.internalNotes')},model:{value:(contact.internal_notes),callback:function ($$v) {_vm.$set(contact, "internal_notes", $$v)},expression:"contact.internal_notes"}})],1):_vm._e(),_c('v-col',{staticClass:"hidden-md-and-up",attrs:{"cols":"12"}},[_c('v-divider')],1)],1)}),_c('v-row',[_c('v-col',{staticClass:"align-self-center",attrs:{"cols":"auto"}},[_c('IconMenu',{attrs:{"items":_vm.contactTypes,"icon":"mdi-plus","row":""}})],1)],1),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_vm._t("buttons")],2),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.valid || _vm.saving,"loading":_vm.saving},on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.$t("button.save")))])],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }