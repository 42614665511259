<template>
  <router-link
    :to="{ name: 'personDetail', params: { personId: person.pk } }"
    class="subdued"
  >
    <PersonName :person="person" :phonebook="phonebook" />
  </router-link>
</template>
<script>
import PersonName from "@/components/blocks/PersonName";

export default {
  name: "PersonNameLink",
  components: { PersonName },
  props: {
    person: { required: true, type: Object },
    phonebook: { default: false, type: Boolean }, // show as in phonebook - last_name first
  },
};
</script>
