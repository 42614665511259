<template>
  <v-dialog v-model="show" max-width="600">
    <v-card>
      <v-card-title>{{ $t("label.suggestContactChange") }}</v-card-title>
      <v-card-text>
        <SuggestContactChangeForm :contact="contact" @save="save" v-if="!saved">
          <template #buttons>
            <v-btn @click="show = false">{{ $t("button.cancel") }}</v-btn>
          </template>
        </SuggestContactChangeForm>
        <div v-else class="text-center pt-12 pb-16">
          <v-icon large color="success" class="py-4">mdi-check</v-icon>
          <div v-text="$t('label.suggestionSent')"></div>
        </div>
      </v-card-text>
      <v-card-actions v-if="saved" class="pb-6 mr-2">
        <v-spacer />
        <v-btn @click="show = false" color="primary">{{
          $t("button.close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SuggestContactChangeForm from "@/components/forms/SuggestContactChangeForm";

export default {
  name: "SuggestContactChangeDialog",

  components: { SuggestContactChangeForm },

  props: {
    value: { required: true, type: Boolean },
    contact: { required: true, type: Object },
  },

  data() {
    return {
      show: this.value,
      contactObj: this.contact,
      saved: false,
    };
  },

  methods: {
    save(data) {
      this.saved = true;
      this.$emit("save", data);
    },
  },

  watch: {
    show() {
      this.$emit("input", this.show);
    },
    value() {
      this.show = this.value;
      this.saved = false;
    },
    contact() {
      this.contactObj = this.contact;
    },
  },
};
</script>

<style scoped></style>
