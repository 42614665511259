<template>
  <span>
    <template v-if="phonebook">
      {{ person.last_name
      }}<span v-if="person.born_last_name"> ({{ person.born_last_name }})</span
      >, {{ person.first_name
      }}<span v-if="person.middle_name"> {{ person.middle_name }}</span
      ><span v-if="person.title_before" class="subdued"
        >, {{ person.title_before }}</span
      ><span v-if="person.title_after" class="subdued"
        >, {{ person.title_after }}</span
      >
    </template>
    <template v-else>
      <span class="subdued">{{ person.title_before }}</span>
      {{ person.first_name }} {{ person.middle_name }} {{ person.last_name
      }}<span v-if="person.born_last_name"> ({{ person.born_last_name }})</span>
      <span class="subdued" v-if="person.title_after"
        >, {{ person.title_after }}</span
      >
    </template>
  </span>
</template>
<script>
export default {
  name: "PersonName",
  props: {
    person: { required: true, type: Object },
    phonebook: { default: false, type: Boolean }, // show as in phonebook - last_name first
  },
};
</script>
