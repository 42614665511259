<template>
  <v-dialog v-model="show" max-width="720">
    <v-card>
      <v-card-title>{{ $t("label.connectPersonOrganization") }}</v-card-title>
      <v-card-text>
        <PerOrgLinkForm
          :per-id="person ? person.pk : null"
          :edited-side="editedSide"
          :org-id="organization ? organization.pk : null"
          :link-id="linkId"
          :relation="relation"
          :freeze-edited-side="!!relation"
          @save="$emit('save')"
          ref="form"
        >
          <template #buttons>
            <v-btn color="secondary" @click="show = false">{{
              $t("button.cancel")
            }}</v-btn>
          </template>
        </PerOrgLinkForm>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import PerOrgLinkForm from "@/components/forms/PerOrgLinkForm";

export default {
  name: "EditPerOrgLinkDialog",

  components: { PerOrgLinkForm },

  props: {
    value: { required: true, type: Boolean },
    person: { required: false, type: Object },
    organization: { required: false, type: Object },
    linkId: { required: false, type: Number },
    editedSide: { default: "", type: String },
    relation: { required: false, type: Object },
  },

  data() {
    return {
      show: this.value,
      personObj: this.person,
      orgObj: this.organization,
    };
  },

  watch: {
    show() {
      this.$emit("input", this.show);
    },
    value() {
      this.show = this.value;
    },
    person() {
      this.personObj = this.person;
    },
    organization() {
      this.orgObj = this.organization;
    },
  },
};
</script>

<style scoped></style>
