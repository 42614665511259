<template>
  <v-autocomplete
    v-model="selected"
    :label="labelClean"
    :items="persons"
    :loading="loading"
    item-text="name"
    item-value="pk"
    :return-object="returnObject"
    :disabled="disabled"
  >
    <template #item="{ item }">
      <PersonName :person="item" phonebook />
      <v-spacer />
      <PersonIcon :person="item" />
    </template>
    <template #selection="{ item }">
      <PersonName :person="item" phonebook />
    </template>
    <template #append-item>
      <v-list-item @click="createPerson">
        <v-list-item-icon>
          <v-icon color="primary">mdi-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="font-weight-black primary--text">
          <v-list-item-title>{{ $t("label.addPerson") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <EditPersonDialog
        v-if="showCreateDialog"
        v-model="showCreateDialog"
        @save="addPerson"
      />
    </template>
  </v-autocomplete>
</template>

<script>
import axios from "axios";
import PersonIcon from "@/components/blocks/PersonIcon";
import { personPhoneBookName } from "@/lib/person";
import PersonName from "@/components/blocks/PersonName";
import { mapActions } from "vuex";
import EditPersonDialog from "@/components/dialogs/EditPersonDialog";

export default {
  name: "PersonSelector",
  components: { EditPersonDialog, PersonName, PersonIcon },
  props: {
    label: { default: "", type: String },
    value: { required: false }, // selected person - either an object or pk - depends on returnObject
    returnObject: { default: false, type: Boolean },
    listUrl: { required: false, type: String },
    disabled: { default: false, type: Boolean },
  },

  data() {
    return {
      persons: [],
      loading: false,
      selected: this.value,
      showCreateDialog: false,
    };
  },

  computed: {
    labelClean() {
      return this.label ? this.label : this.$t("label.person");
    },

    url() {
      return this.listUrl ? this.listUrl : "/api/person/";
    },
  },

  methods: {
    ...mapActions(["showMessage"]),
    async fetchPersons() {
      try {
        this.loading = true;
        let resp = await axios.get(this.url);
        this.persons = resp.data;
        this.persons.forEach((item) => (item.name = personPhoneBookName(item)));
        this.persons.sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        this.showMessage({
          text: "Error fetching persons: " + error,
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    createPerson() {
      this.showCreateDialog = true;
    },
    addPerson(person) {
      this.persons.push(person);
      this.persons.sort((a, b) => a.name.localeCompare(b.name));
      this.showCreateDialog = false;
      if (this.returnObject) {
        this.selected = person;
      } else {
        this.selected = person.pk;
      }
    },
  },

  mounted() {
    this.fetchPersons();
  },

  watch: {
    selected() {
      this.$emit("input", this.selected);
    },
    value() {
      this.selected = this.value;
    },
  },
};
</script>

<style scoped></style>
