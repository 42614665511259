<template>
  <v-dialog v-model="show" max-width="1200">
    <v-card>
      <v-card-title class="mb-4">{{ $t("label.editContacts") }}</v-card-title>
      <v-card-text>
        <EditContactsForm
          :existing-contacts="contacts"
          :object-type="objectType"
          :object-id="objectId"
          @save="$emit('save')"
          ref="editWidget"
        >
          <template #buttons>
            <v-btn @click="show = false">{{ $t("button.close") }}</v-btn>
          </template>
        </EditContactsForm>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import EditContactsForm from "@/components/forms/EditContactsForm";

export default {
  name: "EditContactsDialog",

  components: { EditContactsForm },

  props: {
    value: { required: true, type: Boolean },
    contacts: { required: true, type: Array },
    objectType: { required: true, type: String }, // person or organization
    objectId: { required: true, type: Number },
  },

  data() {
    return {
      show: this.value,
    };
  },

  watch: {
    value() {
      this.show = this.value;
    },
    show() {
      this.$emit("input", this.show);
      if (this.show && this.$refs.editWidget) {
        // reset the form
        this.$refs.editWidget.reset();
      }
    },
  },
};
</script>

<style scoped></style>
