<template>
  <span>
    <TagWidget
      v-for="tag in tags"
      :tag="tag"
      :object-id="objectId"
      :object-type="objectType"
      :key="tag.pk"
      @tag-del="fetchTags"
    />
  </span>
</template>

<script>
import TagWidget from "@/components/blocks/TagWidget";
import TaggedObjectMixin from "@/components/mixins/TaggedObjectMixin";

export default {
  name: "TagList",

  mixins: [TaggedObjectMixin],

  components: { TagWidget },
};
</script>

<style scoped></style>
